

import './sidemenu.css'
import { useLocation,useNavigate } from 'react-router-dom'
import { useState } from 'react';

const Sidemenu=()=>{
  const navigate=useNavigate()
  // user sidemenu
  const contactClick = (e) =>  {
    e.preventDefault()
    navigate('/contact')
  }
  const allBolClick = (e) =>  {
    e.preventDefault()
    navigate('/allbol')
  }
  const newBolClick = (e) =>  {
    e.preventDefault()
    navigate('/newbol')
  }

  // admin sidemenu

  const DashboardClick = (e) =>  {
    e.preventDefault()
    navigate('/admin/dashboard')
  }
  const MemberClick = (e) =>  {
    e.preventDefault()
    navigate('/admin/member')
  }

  const location=useLocation();

  location.pathname.includes('/admin')
  const findLocationAdmin = location.pathname.includes('/admin');
  // admin side 
  const dashboard = location.pathname.includes('/admin/dashboard')
  const member    = location.pathname.includes('/admin/member')
  const findLocationAdminNewBol = location.pathname.includes('/admin/newbol')
  // user side

  const allbol = location.pathname.includes('/allbol')  
  const newbol = location.pathname.includes('/newbol')  
  const contact = location.pathname.includes('/contact')  
  const [isMenuOpen, setMenuOpen] = useState(false);
  const hamburger = () => {
    setMenuOpen(!isMenuOpen);
  }
    return(
      <div className={`left-container${isMenuOpen ? 'mobile-toggle' : ''} ${findLocationAdminNewBol ? 'message-added' : ''}`}>
  <div className="left-menu-section">
    <div className="leftmenu-top">
    <a  className="logo" onClick={findLocationAdmin ?DashboardClick:allBolClick}>
        <img src="/images/Qline_logo.png" alt="QiLines Logo" className="logo1" />
     </a>
     
     <a  className="hamburger icon" onClick={hamburger}>
        <img src="/images/menu-icon.png" alt="QiLines Menu" />
     </a>
     <a  className={`hamburger mobile-icon ${isMenuOpen ? 'open' : ''}`} onClick={hamburger}>
        <img src="/images/menu-icon.png" alt="menu" className="menu-open" />
        <img src="/images/left-arrow.png" alt="menu" className="menu-close" />
    </a>
    </div>
    {location.pathname.includes('/admin') ? 
    (
      <nav className="left-menu">
      <ul>
          <li className='cursor-pointer' onClick={DashboardClick}><a className= {dashboard === true ? 'active':''}>
              <span className="menu-icon">
                  <img src="/images/dashboard-icon.png" alt="Qilines image"/>
              </span>
              <span className="menu-txt" >Dashboard</span></a>
          </li>  
          <li className='cursor-pointer' onClick={(MemberClick)}><a className= {member === true ? 'active':''}>
              <span className="menu-icon">
                  <img src="/images/memeber-icon.png" alt="Qilines image"/>
              </span>
              <span className="menu-txt" >Member Management</span></a>
          </li> 
          
      </ul>
  </nav>
    ):(
         <nav className="left-menu">
      <ul>
        <li className='cursor-pointer' onClick={(allBolClick)}><a className= {allbol === true ? 'active':''}>
            <span className="menu-icon">
              <img src="/images/all-bol-icon-blue.png" alt="Qilines image" />
            </span>
            <span className="menu-txt">All BOL</span></a>
        </li>  
        <li className='cursor-pointer' onClick={(newBolClick)}><a  className= {newbol === true ? 'active':''}>
            <span className="menu-icon" onClick={(contactClick)}>
              <img src="/images/new-bol-icon.png" alt="Qilines image" />
            </span>
            <span className="menu-txt">New BOL</span></a>
        </li> 
        <li className='cursor-pointer' onClick={(contactClick)}><a  className= {contact === true ? 'active':''}>
            <span className="menu-icon">
              <img src="/images/phone-book.png" alt="Qilines image" />
            </span>
            <span className="menu-txt">Address Book</span></a>
        </li> 
      </ul>
    </nav>
    ) }
 
    <div className="footer-companycopyright">
    <a >Qi Lines © 2023 - 2024 <br></br> All Rights Reserved</a>
    </div>
  </div>
</div>

    )
}
export default Sidemenu