import React, { useState, useEffect, useRef } from 'react';
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from "react-redux"
import { closeChatBox } from "../features/popup/popupSlice"
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_BOL_CONVERSATIONS } from '../query/query';
import { client2 } from '../services/appolo';

const BroadCast = ({bolId,bolNumber,mutation}) => {
  // State to store chat messages
  const [messages, setMessages]         = useState([]);
  const [newMessage, setNewMessage]     = useState('');
  const location                        = useLocation();
  const currentLocation                 = location.pathname.includes('/admin/view');
  const [page, setPage]                 = useState(1);
  const{broadCast}                      = useSelector(state=>state.popup)
  const pusherKey                       = process.env.REACT_APP_PUSHER_KEY;
  const pusherCluster                   = process.env.REACT_APP_PUSHER_CLUSTER;
  const containerRef                    = useRef(null);
  const [hasMorePages, setHasMorePages] = useState(true);



  // api call start

 // get all bol messages

 const {} = useQuery(GET_BOL_CONVERSATIONS,{

  variables: {
    bol_id: bolId,
    first:6,
    page: page,
  },
  client: client2,
  fetchPolicy: 'no-cache', // Disable caching for this query
  onCompleted: (data) => {
    // console.log(data)
    // setMessages(data.getBolConversations.data)
    setHasMorePages(data.getBolConversations.paginatorInfo.hasMorePages)
    const reversedData = [...data.getBolConversations.data].reverse(); // Create a copy of the array and reverse it
    setMessages((prevMessages) => [...reversedData, ...prevMessages]);
    if(page !== 1){
      scrollToPreviousLastItem();
    }
  }
})

  // Function to check if the scrollbar is at the top
  const isScrollAtTop = () => {
    const container = containerRef.current;
    if (container) {
      return container.scrollTop === 0;
    }
    return false;
  };

    // Function to scroll to the previous last item's position
    const scrollToPreviousLastItem = () => {
      const container = containerRef.current;
      if (container) {
        // Calculate the scroll position based on the previous last item's offsetTop
        const previousLastItem = container.children[0];
        if (previousLastItem) {
          // const scrollPosition = previousLastItem.offsetTop;
          container.scrollTop = 210;
        }
      }
    };

    // Handle scrolling event
    const handleScroll = () => {
      if (isScrollAtTop()) {
        // If scroll is at the top, increment the page count
        if(hasMorePages === true){
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

  // api call end
  // Initialize Pusher
  useEffect(() => {
    if(page === 1){
      scrollToBottom();
    }

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
 
    // Pusher.logToConsole = true;

    const pusher = new Pusher(pusherKey, {
      cluster: pusherCluster,
    });
    // Create a new Date object
    const currentDate = new Date();

      // Get the UTC date components
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with '0' if needed
    const day = String(currentDate.getUTCDate()).padStart(2, '0');

    // Get the UTC time components
    const hours = String(currentDate.getUTCHours()).padStart(2, '0');
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');

    // Create the formatted UTC date string
    const formattedUtcDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    // Subscribe to the chat channel
    const channel = pusher.subscribe(`${'bol-messages-'+bolNumber}`);

    // Bind to the 'bol.member.message' event
    channel.bind('bol-member-message', (data) => {
        // Handle user messages
        handleNewMessage({ ...data, send_by: 'Admin', reciever:'Member',timestamp:formattedUtcDate });
      });
  
      channel.bind('bol-admin-message', (data) => {
        // Handle admin messages
        handleNewMessage({ ...data, send_by: 'Member', reciever:'Admin',timestamp:formattedUtcDate });
      });

    // Clean up when the component unmounts
    return () => {
        channel.unbind('bol-member-message');
        channel.unbind('bol-admin-message');
      
    //   channel.unbind('bol-admin-message');
      pusher.unsubscribe(`${'bol-messages-'+bolNumber}`);
      pusher.disconnect(`${'bol-messages-'+bolNumber}`);
          // Clean up the scroll event listener when the component unmounts
          if (container) {
            container.removeEventListener('scroll', handleScroll);
          }
    };
  
   

  }, []);

  const textareaRef = useRef(null);
  useEffect(() => {
    const textarea = textareaRef.current;

    const adjustHeight = () => {
      textarea.style.height = 'auto';
      if (textarea.scrollHeight <= 110) {
        textarea.style.height = `${Math.max(textarea.scrollHeight, 20)}px`;
        textarea.style.overflowY = 'hidden';
      } else {
        textarea.style.height = '110px';
        textarea.style.overflowY = 'scroll';
      }
    };
    // Call adjustHeight initially to set the initial height
    adjustHeight();

    // Add an input event listener to adjust the height when the content changes
    textarea.addEventListener('input', adjustHeight);

    return () => {
      // Remove the input event listener when the component unmounts
      textarea.removeEventListener('input', adjustHeight);
    };
  }, []);




  // Function to check if the scrollbar is at the bottom
  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      // Use requestAnimationFrame to ensure scrolling occurs after rendering
      requestAnimationFrame(() => {
        container.scrollTop = container.scrollHeight;
      });
    }
  };

  // Function to handle a new message
  const handleNewMessage = (comments) => {
    // Add the new message to the state
    setMessages((prevMessages) => [...prevMessages, comments]);
    scrollToBottom();
    
  };

  // Function to send a new user message
  const sendMessage = () => {
    if (newMessage.trim() !== '') {
        mutation({variables:{bol_number:bolNumber,id:bolId,message:newMessage}})
  
        setNewMessage('');
      }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage(e);
    }
  };
  const dispatch=useDispatch()
  const onClose=()=>{
      dispatch(closeChatBox())
   }
  return (
    <>
    {/* <div className="popupbg-overlay"></div> */}
   
        <div className={broadCast.isOpen === true ? "popup-chat-box active":"popup-chat-box"}>
            <div className="popupbox-wrapper" style={{padding:"0px"}}>
           
                <div className="popupbox-content">
                    <div className="message-popup">
                        <div className="flex message-header">
                            <h4 className="chat-title">Message</h4>
                            <div className="popup-close">
                                <img src="/images/close-icon-white.png" alt="Close icon" onClick={onClose}/>
                            </div>
                        </div>
                        
                        {/* <form className="chat-form"> */}
                            <div className="message-body">
                                <div className="message-display" ref={containerRef} >
                                
                                {messages.map((message, index) => (
                                  <>
                                    <div className={message.message_heading? 'message-heading' : ''}>{message?.message_heading}</div>
                                   {message.comments !== '' && (
                                        <div
                                        key={index}
                                        className={`message-wrap-${(message.send_by === 'Admin' && currentLocation === true ) ||(message.send_by === 'Member' && currentLocation !== true  )? 'reply' : 'send'}`}
                                      >
                                        <div
                                          className={`send-msg ${(message.send_by === 'Admin' && currentLocation === true) || (message.send_by === 'Member' && currentLocation !== true) ? 'reply' : ''}`}
                                        >
                                          {message.comments}
                                        </div>
                                        <div className="timestamp">{message.timestamp}</div>
                                      </div>
                                   )}
                               
                                  </>
                                ))}
                                </div>
                                <form className="chat-form" onKeyPress={handleKeyPress}>
                                <div className="message-typing-box">
                                <div className="message-box-input">
                                <textarea
                                ref={textareaRef}
                                style={{minHeight: '20px', maxHeight: '110px', overflowY: 'hidden'}}
                                rows='1'
                                  type="text"
                                  className="message-input"
                                  placeholder="Type message..."
                                  value={newMessage}
                                  onChange={(e) => setNewMessage(e.target.value)}
                                ></textarea>
                                <button type="button" className="message-submit" onClick={sendMessage}>
                                  
                                </button>
                              </div>
                                </div>
                        </form>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};
export default BroadCast
