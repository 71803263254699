import "./newbol.css";
import Sidemenu from "../../components/layout/Sidemenu";
import Header from "../../components/layout/Header";
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation } from "@apollo/client";
import { toast } from 'react-toastify';
import { client3} from '../../services/appolo';
import {CREATE_BOL_MUTATION } from '../../mutation/mutation';
import { EDIT_BOL } from "../../mutation/mutation";
import BolContent from "../../components/BolContent";
import { useNavigate, useLocation} from 'react-router-dom'
import { Helmet } from "react-helmet";
import Spinner from "../../components/common/Spinner";

const NewBol = () => {
  const location=useLocation();
  const navigate = useNavigate()
  const findLocationEdit  = location.pathname.includes('/bol/edit');
  const findLocationView = location.pathname.includes("/admin/view");
  
  
  const [createBol, { loading:bolloading }] = useMutation(CREATE_BOL_MUTATION ,{
    client: client3,
    onCompleted:(data)=>{
      toast.success(' BOL  created successfully.')
      const bolCreateId = data.createBol.id; 
        
      if (findLocationView === true) {
        navigate('/admin/view/bol',{state: { data: {bolId: bolCreateId,creatorId:data.createBol.creator_id} },})

      } else {
        navigate('/bol/view',{state: { data: {bolId: bolCreateId} },})

        
      }
    },
    onError: () => {
      // Show an error toast
    },
  });




  const [updateBolMutation, { loading:updateloading }] = useMutation(EDIT_BOL ,{
    client: client3,
  
    onCompleted:(data)=>{
      // toast.success(' Bol form updated successfully.')
      const bolUpdateId = data.saveUpdateBol.id; 
        navigate('/bol/view',{state: { data: {bolId: bolUpdateId} },})

      
    },
    onError: () => {
      // Show an error toast
    },
  });

  // if (bolloading || updateloading)   return <Spinner/>
  return (
    <>
    {(bolloading || updateloading) && <Spinner/>}
      <Helmet><title>{findLocationEdit === true ? 'Edit BOL - QiLines':'New BOL - QiLines'}
  </title></Helmet>
        {/* {<Modal content={popupContent=='confirmSubmit'?<ConfirmPopup submitConfirm={bolSubmit} title='Do you really want to submit BOL form ?'/>:popupContent=='Shipper'|| 'Consignee' || 'Notify'?<BolPopUp title={popupContent=='Shipper'?'Shipper':popupContent=='Consignee'?'Consignee':popupContent=='Notify'?'Notify':''} />:''} />} */}
      <div className="outerMainWrapper">
        <Sidemenu />
        <div className="right-container">
          <Header title={findLocationEdit === true ? 'Edit BOL':'New BOL'}/>
          <div className="content-outer-wrapper">

          <BolContent createMutation ={createBol} UpdateMutation = {updateBolMutation}  id = ''/>

          </div>
        </div>
      </div>
    </>
  );
};
export default NewBol;
