import '../../newBol/newbol.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { client3 } from '../../../services/appolo';
import {  useMutation } from '@apollo/client';
import { CREATE_BOL_MUTATION } from "../../../mutation/mutation"
import { Helmet } from "react-helmet";
import Spinner from '../../../components/common/Spinner';
import Sidemenu from '../../../components/layout/Sidemenu';
import Header from '../../../components/layout/Header';
import BolContent from '../../../components/BolContent';
const AdminNewBol = () => {
    const location=useLocation();
    const navigate = useNavigate()
    const {memberId,companyName,memberEmail} =location.state.data
    const findLocationAdminNewbol = location.pathname.includes("/admin/newbol");
    
    const [createBol, { loading:bolloading }] = useMutation(CREATE_BOL_MUTATION ,{
      client: client3,
      onCompleted:(data)=>{
        toast.success(' BOL  created successfully.')
        const bolCreateId = data.createBol.id; 
          
        if (findLocationAdminNewbol === true) {
          navigate('/admin/view/bol',{state: { data: {bolId: bolCreateId,creatorId:data.createBol.creator_id} },})
        } else {
          navigate('/bol/view',{state: { data: {bolId: bolCreateId} },})

          
        }
      },
      onError: () => {
        // Show an error toast
      },
    });
  
    // if (bolloading || updateloading)   return <Spinner/>
    return (
      <>
      {(bolloading ) && <Spinner/>}
        <Helmet><title>New BOL - QiLines
    </title></Helmet>
        <div className="outerMainWrapper">
          <Sidemenu />
          <div className={findLocationAdminNewbol === true ?"right-container message-added":"right-container"}>
          {findLocationAdminNewbol === true &&<div> <h3 className="bol-create-message message-fixed"><img src="/images/i20.svg" alt="Badge Icon"/>Creating BOL on behalf of ({companyName})</h3></div> }

            <Header title='New BOL'/>
            <div className="content-outer-wrapper">
            <BolContent createMutation ={createBol} UpdateMutation = {''}  id = '' memberId = {memberId} companyName = {companyName} memberEmail = {memberEmail}/>
            </div>
          </div>
        </div>
      </>
    );
  };
  export default AdminNewBol;