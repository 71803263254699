
import './BolView.css'
import Header from '../../../components/layout/Header'
import Sidemenu from '../../../components/layout/Sidemenu'
import { useState, useEffect } from 'react'
import BolCommonView from '../../../components/BolViewContent'
import Modal from '../../../components/model/Modal'
import ApproveRejectPopup from '../../../components/ApproveReject'
import { useDispatch } from 'react-redux'
import { openPopup,closeChatBox, activeNotification } from '../../../features/popup/popupSlice'

import {  client2, client3, client5 } from '../../../services/appolo'
import { useLazyQuery } from '@apollo/client'
import {  GET_BOL_UNIQUENESS_DATA } from '../../../query/query'
import { Helmet } from 'react-helmet'
import Spinner from '../../../components/common/Spinner'
import { useMutation, useQuery } from '@apollo/client'
import { SEND_BOL_MESSAGE } from '../../../mutation/mutation'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { GET_MEMBER_DETAILS_FOR_FIND_ACTIVE } from '../../../query/query'
const AdminBolView=()=>{
   
    const dispatch                                          = useDispatch()
    const [approveRejectParam, setApproveRejectParam]       = useState('')
    const [allListofVersions, setAllListOfVersions]         = useState([])
    // const [currentVersionDetails, setCurrentVersionDetails] = useState([])
    // const params                                            = useParams();
    // const bolId                                             = params.id;
    const location=useLocation();
    const {bolId,creatorId}                                 =location.state.data
    const findLocationAdminView                             = location.pathname.includes('/admin/view');
    const [executed, setExecuted]                           = useState(false);
    const{broadCast, isActiveNotification}                  = useSelector(state=>state.popup)
    const [userStatus, setUserStatus]                       = useState('')

            // --------------------Api call start-------------------------//
       
       //  get  all versions of bol api call

       const  [getAllVersionsOfBol, { loading:loadingbolall}] = useLazyQuery(GET_BOL_UNIQUENESS_DATA, {
        skip:!executed,
        variables:{
            id:bolId
        },
        client:client2,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          dispatch(activeNotification())
          if (data?.getAllVersionsOfABol) {
            setAllListOfVersions(data.getAllVersionsOfABol);
          }
        },
        onError: () => {
          // Show an error toast
        },
      });
    

      // send bol messages

      const [sendBolMessage] = useMutation(SEND_BOL_MESSAGE,{
        client: client3,
    
        onCompleted:(data)=>{
       
        },
        onError: () => {
          // Show an error toast
        },
      });

      const   { loading } = useQuery(GET_MEMBER_DETAILS_FOR_FIND_ACTIVE, {
        // skip:!findLocationAdminView,
       variables:{
         id:creatorId
       },
        client:client5,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          setExecuted(true);
          setUserStatus(data.getMemberDetails.status)
        },
        onError: () => {
          // Show an error toast
        },
      });
    
      // -------------------Api call end-------------------------//
 
      useEffect(() => {
       return ()=>{
        dispatch(closeChatBox())
       }
      }, []);
      useEffect(() => {
        if (executed) {
          getAllVersionsOfBol({variables:{id:bolId}})
            // getBolVersionDetail({variables:{id:bolId}})
          }
      }, [executed,getAllVersionsOfBol,bolId]);
  
    const approveBol = (e,param) => {
      setApproveRejectParam(param)
      e.stopPropagation();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      dispatch(openPopup())
    }
    const rejectBol = (e,param) => {
      setApproveRejectParam(param)
      e.stopPropagation();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      dispatch(openPopup())
    }  
    // if ( loadingbolall )   return <Spinner/>
   return(
    <>  
    {(loadingbolall || loading) && <Spinner/>}
            <Helmet><title>BOL View - QiLines
  </title></Helmet>
     {<Modal content={<ApproveRejectPopup   Id = {bolId} title = {approveRejectParam} message={approveRejectParam === "Approve"? "Approval Comments":"Rejection Comments"}/>} />}
    <div className="outerMainWrapper">
    <Sidemenu />
    <div className={findLocationAdminView === true && userStatus === 0? "right-container message-added":"right-container"}>
    {findLocationAdminView === true && userStatus === 0?<h3 className='deactivatte-msg message-fixed'>This user has been deactivated.</h3>:''}
      <Header title="Bill Of Lading"/>
    <BolCommonView approveBol = {approveBol} rejectBol={rejectBol} allListofVersions={allListofVersions}   popupShow={''} loading={loadingbolall} mutation={sendBolMessage} bolNumber ={broadCast.channel} userStatus={userStatus}/>

</div>

  </div>
  </>



   )
}
export default AdminBolView