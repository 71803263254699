import React ,{ useState, useEffect } from "react";
import CustomInput from "../components/common/CustomInput";
import { useDispatch } from "react-redux";
import { openPopup, closePopup, activeNotification,openWarningPopup } from "../features/popup/popupSlice";
import EditableTable from "./EditableTable";
import DatePicker from "react-datepicker";
import BolPopUp from "./BolPopUp";
import ConfirmPopup from "./ConfirmPopup";
import Modal from "./model/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import { client2, client3 } from "../services/appolo";
import { toast } from "react-toastify";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_CONTACT_LIST_TO_BOL } from "../query/query";
import {CREATE_CONTACT,} from "../mutation/mutation";
import { useParams } from "react-router-dom";
import { GET_BOL_DETAILED_DATA } from "../query/query";
import Spinner from "./common/Spinner";
import WarningPopup from "./WarningPopup";
const BolContent = ({
  createMutation = () => {},
  UpdateMutation = () => {},
  id = "", memberId = "", companyName = "", memberEmail ="",
}) => {
  const location = useLocation();
  const params = useParams();
  const bolId = params.id;
  const findLocationAdmin = location.pathname.includes("/admin");
  const findLocationView = location.pathname.includes("/admin/view");
  const findLocationAdminEdit = location.pathname.includes("/admin/edit");
  const findLocationMemberEdit = location.pathname.includes("/bol/edit");
  const findLocationNewBol = location.pathname.includes("/newbol");
  const findLocationAdminNewBol = location.pathname.includes("/admin/newbol");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [executed, setExecuted] = useState(false);
  const [contactArray, setContactArray] = useState([]);
  const [notifyPartyId, setNotifyPartyId] = useState("");
  const [shipperId, setShipperId] = useState("");
  const [consigneeId, setConsigneeId] = useState("");
  const [dropData, setDropData] = useState("As Agent of The Carrier of Qi Lines");
  const [validationFlag] = useState(true);
  const [tableonheading] = useState([
    "MRKS & NOS/CNTR",
    "#OF PKGS",
    "Description of Packages and Goods",
    "Gross Weight",
    "Measurement",
  ]);
  const [tabletwoheading] = useState([
    "Freight Charges",
    "Basics",
    "Rate",
    "Prepaid",
    "Collect",
  ]);

  const [currentVersionId, setCurrentVersionId] = useState("");
  const [creatorId, setCreatorId] = useState(parseInt(memberId));
  const [creatorName, setCreatorName] = useState(companyName);
  const shipmentDrop = ["","Port-to-port", "Multi Modal Transport"];
  const [shipmentDropData, setShipmentDropData] = useState("");
  const [billingNo, setBillingNo] = useState("");
  const [issuedOn, setIssuedOn] = useState(null);
  const [remarkChecked, setRemarkChecked] = useState(false);
  const [creator_email, setCreatorMail] = useState(memberEmail);
  const [versionStatus, setVersionStatus] = useState("");
  const [flag, setFlag] = useState(false);
  const [showLoader,setShowLoader] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState(false);
  const [obj, setObj] = useState({
    exportRef: "",
    pointGoods: "",
    preCarriage: "",
    placeRe: "",
    exportCarrier: "",
    portLoading: "",
    loadingTerminal: "",
    portDischarge: "",
    placeDelivery: "",
    noOrginals: "",
    prepaidTotal: "",
    collectTotal: "",
    issueAt: "",
    fmcFiling: "",
    fwdNo: "",
    shipperaddress: "",
    consigneeaddress: "",
    delivery: "",
    notifyaddress: "",
    bookingNumber: "",
    issuedBy: "",
  });

  const {
    exportRef,
    pointGoods,
    preCarriage,
    placeRe,
    exportCarrier,
    portLoading,
    loadingTerminal,
    portDischarge,
    placeDelivery,
    noOrginals,
    prepaidTotal,
    collectTotal,
    issueAt,
    fmcFiling,
    fwdNo,
    shipperaddress,
    consigneeaddress,
    delivery,
    notifyaddress,
    bookingNumber,
    issuedBy,
  } = obj;

  const [rows, setRows] = useState([
    {
      id: 1,
      values: ["", "", "", "", ""],
    },
    // {
    //     id:2,
    //     values:['','','','','']
    // }
  ]);

  const [rowsone, setRowsone] = useState([
    {
      id: 1,
      values: ["", "", "", "", ""],
    },
  ]);

  const {
    loading,
  } = useQuery(GET_CONTACT_LIST_TO_BOL, {
    skip: !flag,
    client: client2,
    fetchPolicy: 'no-cache', // Disable caching for this query
    onCompleted: (data) => {
      if(!findLocationAdminEdit || !findLocationMemberEdit){
      dispatch(activeNotification())
      }
      setContactArray(data.getAllContactsOfMember);
    },
    onError: () => {
      // Show an error toast
    },
  });

  //  get  all versions of bol api call

  // const [
  //   getAllVersionsOfBol,
  //   { loading: loadingbolall },
  // ] = useLazyQuery(GET_BOL_UNIQUENESS_DATA, {
  //   skip: !executed,
  //   variables: {
  //     id: bolId,
  //   },
  //   client: client2,
  //   fetchPolicy: 'no-cache', // Disable caching for this query
  //   onCompleted: (data) => {
  //     if (data?.getAllVersionsOfABol) {
  //       // setAllListOfVersions(data.getAllVersionsOfABol);
  //     }
  //   },
  //   onError: () => {
  //     // Show an error toast
  //   },
  // });

  const [
    getBolDetails,
    { loading: loadingdetails },
  ] = useLazyQuery(GET_BOL_DETAILED_DATA, {
    skip: !executed,
    variables: {
      id: bolId,
    },
    client: client2,
    fetchPolicy: 'no-cache', // Disable caching for this query
    onCompleted: (data) => {
      dispatch(activeNotification())
      setFlag(true)
      if (data?.getBolDetails) {
        // setAllListOfDetails(data.getBolDetails);
        const newDetails = { ...obj };
        newDetails.exportRef = data.getBolDetails.export_references;
        newDetails.pointGoods = data.getBolDetails.country_of_origin_goods;
        newDetails.preCarriage = data.getBolDetails.pre_carriage_by;
        newDetails.placeRe = data.getBolDetails.pre_carrier_reciept_place;
        newDetails.exportCarrier = data.getBolDetails.export_carrier;
        newDetails.portLoading = data.getBolDetails.port_of_loading === null ? "" : data.getBolDetails.port_of_loading;
        newDetails.loadingTerminal = data.getBolDetails.loading_pier_terminal;
        newDetails.portDischarge = data.getBolDetails.port_of_discharge === null ? "" : data.getBolDetails.port_of_discharge;
        newDetails.placeDelivery = data.getBolDetails.carrier_place_delivery;
        newDetails.noOrginals = data.getBolDetails.number_of_originals;
        newDetails.collectTotal = data.getBolDetails.collectTotal;
        newDetails.issueAt = data.getBolDetails.issued_at === null ? "" : data.getBolDetails.issued_at;
        newDetails.fmcFiling = data.getBolDetails.selling_rate_authorization === null ? "" : data.getBolDetails.selling_rate_authorization;
        newDetails.fwdNo = data.getBolDetails.forwarding_agent_fmc_number;
        newDetails.delivery = data.getBolDetails.delivery_apply_to;
        newDetails.bookingNumber = data.getBolDetails.booking_number;
        newDetails.prepaidTotal = data.getBolDetails.prepaid_total;
        newDetails.collectTotal = data.getBolDetails.collect_total;
        newDetails.issuedBy = data.getBolDetails.issued_by === null ? "" : data.getBolDetails.issued_by;
        // newDetails.shipperaddress= data.getBolDetails.shipper?`${data.getBolDetails.shipper.company_name}${data.getBolDetails.shipper.address !== null ?`\n${data.getBolDetails.shipper.address}`:''}${data.getBolDetails.shipper.city !== null ?`\n${data.getBolDetails.shipper.city}`:''}${data.getBolDetails.shipper.state !== null ?`\n${data.getBolDetails.shipper.state}`:''}\n${data.getBolDetails.shipper.country_name}${data.getBolDetails.shipper.contact_number !== null ?`\nPh:${data.getBolDetails.shipper.contact_number}`:''}`:"";
        // newDetails.consigneeaddress= data.getBolDetails.consignee?`${data.getBolDetails.consignee.company_name}${data.getBolDetails.consignee.address !== null ?`\n${data.getBolDetails.consignee.address}`:''}${data.getBolDetails.consignee.city !== null ?`\n${data.getBolDetails.consignee.city}`:''}${data.getBolDetails.consignee.state !== null ?`\n${data.getBolDetails.consignee.state}`:''}\n${data.getBolDetails.consignee.country_name}${data.getBolDetails.consignee.contact_number !== null ?`\nPh:${data.getBolDetails.consignee.contact_number}`:''}`:"";
        // newDetails.notifyaddress=data.getBolDetails.notify_party?`${data.getBolDetails.notify_party?.company_name}${data.getBolDetails.notify_party.address !== null ?`\n${data.getBolDetails.notify_party.address}`:''}${data.getBolDetails.notify_party.city !== null ?`\n${data.getBolDetails.notify_party.city}`:''}${data.getBolDetails.notify_party.state !== null ?`\n${data.getBolDetails.notify_party.state}`:''}\n${data.getBolDetails.notify_party?.country_name}${data.getBolDetails.notify_party.contact_number !== null ?`\nPh:${data.getBolDetails.notify_party.contact_number}`:''}`:"";
        newDetails.shipperaddress = data.getBolDetails?.shipper_details
        newDetails.consigneeaddress = data.getBolDetails?.consignee_details
        newDetails.notifyaddress = data.getBolDetails?.notify_party_details
    

        setObj(newDetails);
       

        setShipperData(data.getBolDetails.shipper === null ? "" : data.getBolDetails.shipper.company_name);
        setConsigneeData(data.getBolDetails.consignee === null ? "" : data.getBolDetails.consignee.company_name);
        setNotifyData(data.getBolDetails.notify_party?.company_name);
        setCurrentVersionId(data.getBolDetails.id);
        setIssuedOn(data.getBolDetails.issued_on === null ? "" : data.getBolDetails.issued_on);
        setShipmentDropData(data.getBolDetails.shipment_type);
        // setParentId(data.getBolDetails.bol_parent_id);
        setBillingNo(data.getBolDetails.bol_number);
        setRemarkChecked(data.getBolDetails.accordance_with_regulations);
        setDropData(data.getBolDetails.issued_by_as);
        const ship_id = parseInt(data.getBolDetails.shipper?.id);
        setShipperId(ship_id);
        const con_id = parseInt(data.getBolDetails.consignee?.id);
        setConsigneeId(con_id);
        const notfy_id = parseInt(data.getBolDetails.notify_party?.id);
        setNotifyPartyId(notfy_id);
        if(findLocationAdminEdit === true){
          const creator_id = parseInt(data.getBolDetails.creator_id);
          setCreatorId(creator_id);
          setCreatorName(data.getBolDetails.creator_name);
          setCreatorMail(data.getBolDetails.creator_email);
        }
        setVersionStatus(data.getBolDetails.bol_status);
        // setBolEditStatus(data.getBolDetails.is_editable);
        const arrayone = data.getBolDetails.shippers_declared_values;
        let arraythree = [];
        arrayone.forEach((item, index) => {
        
          const arr = Object.values(item);
          arr.splice(0, 1)
          arr.pop()
          const obj = {
            id: item.id,
            values: arr,
          };
          arraythree.push(obj);
        });
      if(arraythree.length === 2){
        // setShipperAddRow(false)
      }
        setRows(arraythree);

        const particulararray = data.getBolDetails.particulars_furnished;
        let particularone = [];
        particulararray.forEach((item, index) => {
        
          const particulararr = Object.values(item);
          particulararr.splice(0,1)
          particulararr.pop()
          const obj1 = {
            id: item.id,
            values: particulararr,
          };
          particularone.push(obj1);
        });
        if(particularone.length === 2){
          // setPerticularAddRow(false)
        }
        setRowsone(particularone);
      }
    },
    onError: () => {
      // Show an error toast
    },
  });

  // contact create api

  const [
    createContactMutation,
    { loading: loading1 },
  ] = useMutation(CREATE_CONTACT, {
    client: client3,

    onCompleted: (data) => {
      toast.success("Contact created successfully.");
      dispatch(closePopup());
      setContactArray([...contactArray,data.createContact])
    },
    onError: () => {
      // Show an error toast
    },
  });

  useEffect(() => {
    setExecuted(true);
    return () => {};
  }, []);
  useEffect(() => {
    if (executed) {
      if (
        findLocationMemberEdit === true ||
        findLocationAdminEdit === true ||
        findLocationView === true
      ) {
        // getAllVersionsOfBol({ variables: { id: bolId } });
        getBolDetails({ variables: { id: bolId } });
      }else{
        setFlag(true)
      }
    }
  }, [executed, getBolDetails]);

  const drop = [
    "As Agent of The Carrier of Qi Lines",
    "As Carrier",
  ];
 
 

  const popupShow = (contentName) => {
    setPopupContent(contentName);
    dispatch(openPopup());
  };
  let newRow = {};
  const addNew = () => {
    setShowLoader(true);
    if (!findLocationMemberEdit || !findLocationAdminEdit) {
     
        newRow = { id: rowsone.length + 1, values: ["", "", "", "", ""] };
       
    } else {
        
          newRow = { id: "", values: ["", "", "", "", ""] };
         
    }

    setRowsone([...rowsone, newRow]);
    setTimeout(() => {
      setShowLoader(false);
      toast.success("This additional box will be added as a rider page in this HBL");
    }, 500);
  };

 

  const handleInputChangetwo = (e, row, columnindex) => {
    const MAX_LINES = 18;
    const characterLimits = [12, 10, 42, 12, 12]; // Define character limits for each column
    const MAX_CHARACTERS_PER_LINE = characterLimits[columnindex];
    // Limit the text to 20 lines
    let lines = e.target.value.split('\n');
    if (lines.length > MAX_LINES) {
      // Truncate lines if the maximum number of lines is exceeded
      lines = lines.slice(0, MAX_LINES);
    }
    // Iterate through each line and check for character limits
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > MAX_CHARACTERS_PER_LINE) {
        // Split the line into multiple lines if it exceeds the character limit
        const overflow = lines[i].substring(MAX_CHARACTERS_PER_LINE);
        lines[i] = lines[i].substring(0, MAX_CHARACTERS_PER_LINE);
  
        // Insert the overflow text as a new line
        lines.splice(i + 1, 0, overflow);
  
        // Check if the maximum number of lines is exceeded after splitting
        if (lines.length > MAX_LINES) {
          // Truncate lines again to meet the line limit
          lines = lines.slice(0, MAX_LINES);
          break;
        }
      }
    }
  
    const updatedText = lines.join('\n');
  
    const updatedRow = rowsone.map((r, index) => {
      if (r.id === row.id) {
        const updatedValues = [...r.values];
        updatedValues[columnindex] = updatedText;
        return { ...r, values: updatedValues };
      }
      return r;
    });
  
    setRowsone(updatedRow);
  };


  const perticularTableMinHeight =  210;
  const numberOfTextareas = 5;
  const numberOfRows = rowsone.length; // Change this to the number of rows you have

const perticularTextareaRefs = Array.from({ length: numberOfRows }, () =>
  Array.from({ length: numberOfTextareas }, () => React.createRef(null))
);
React.useLayoutEffect(() => {
  // Iterate over the textarea refs
  perticularTextareaRefs.forEach((rowRefs) => {
    rowRefs.forEach((textareaRef) => {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = "auto";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        perticularTableMinHeight
      )}px`;
    });
  });
}, [perticularTextareaRefs]);


  // let newRows = {};
  // const handleAddRow = () => {
  //     if(!findLocationMemberEdit || !findLocationAdminEdit){
       
  //         newRows = { id: rows.length + 1, values: ["", "", "", "", ""] };
      
  //     }else{
        
  //         newRows = { id:'', values: ["", "", "", "", ""] };
      
  //     }
  
  //   setRows([...rows, newRows]);
  // };
 
 
 

 
  const handleInputChanges = (e, row, columnindex) => {
    const MAX_LINES = 5;
    const characterLimits = [15, 15, 15, 15, 15]; // Define character limits for each column
    const MAX_CHARACTERS_PER_LINE = characterLimits[columnindex];
    // Limit the text to 20 lines
    let lines = e.target.value.split('\n');
    if (lines.length > MAX_LINES) {
      // Truncate lines if the maximum number of lines is exceeded
      lines = lines.slice(0, MAX_LINES);
    }
    // Iterate through each line and check for character limits
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > MAX_CHARACTERS_PER_LINE) {
        // Split the line into multiple lines if it exceeds the character limit
        const overflow = lines[i].substring(MAX_CHARACTERS_PER_LINE);
        lines[i] = lines[i].substring(0, MAX_CHARACTERS_PER_LINE);
  
        // Insert the overflow text as a new line
        lines.splice(i + 1, 0, overflow);
  
        // Check if the maximum number of lines is exceeded after splitting
        if (lines.length > MAX_LINES) {
          // Truncate lines again to meet the line limit
          lines = lines.slice(0, MAX_LINES);
          break;
        }
      }
    }
  
    const updatedText = lines.join('\n');
    const updatedRow = rows.map((r, index) => {
      if (r.id === row.id) {
        const updatedVlues = [...r.values];
        updatedVlues[columnindex] = updatedText;
        return { ...r, values: updatedVlues };
      }
      return r;
    });
    setRows(updatedRow);
  };


  const shipperTableMinHeight = 70;
const shipperTextareaRefs = Array.from({ length: 1 }, () =>
  Array.from({ length: numberOfTextareas }, () => React.createRef(null))
);
React.useLayoutEffect(() => {
  // Iterate over the textarea refs
  shipperTextareaRefs.forEach((rowRefs) => {
    rowRefs.forEach((textareaRef) => {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = "auto";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        shipperTableMinHeight
      )}px`;
    });
  });
}, [shipperTextareaRefs]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    const alphabets = inputValue.replace(/[^a-zA-Z]/g, "").toUpperCase();

    const numbers = inputValue.replace(/[^0-9]/g, "");

    const formattedValue = alphabets.slice(0, 6) + numbers.slice(0, 4);

    setBillingNo(formattedValue);
    setErrorMessage((prevError) => ({
      ...prevError,
      hasErrorInbillingNo: false,
    }));
  };

  const handleCheckboxChange = (event) => {
    setRemarkChecked(event.target.checked);
  };

  // const NumberCheck = (e) => {
  //   const inputValue = e.target.value;
  //   const sanitizedValue = inputValue.replace(/\D/g, "");
  //   setBookingNumber(sanitizedValue);
  // };


  const [shipperData, setShipperData] = useState("");
  const [consigneeData, setConsigneeData] = useState("");
  const [notifyData, setNotifyData] = useState("");
  const dropList = (e) => {
    const { name, value } = e.target;
    if(value ==="defaultNotifyParty"){
      handleClearNotifyParty()
    }else if(value ==="defaultShipper"){
      handleClearShipper()
    }else if(value ==="defaultConsignee"){
      handleClearConsignee();
    }
    if (
      name === "shipperData" ||
      name === "consigneeData" ||
      name === "notifyData"
    ) {
      const contactObj = contactArray.find(
        (item, index) => item.company_name === value
      );
      if (contactObj) {
        if (name === "shipperData") {
          removeToolTip(name);
          onChange({
            target: {
              name: "shipperaddress",
              value: `${contactObj.company_name}${contactObj.address !== null ? `\n${contactObj.address}` : ''}${contactObj.city !== null ? `\n${contactObj.city}` : ''}${contactObj.state !== null ? `\n${contactObj.state}` : ''}\n${contactObj.country_name}${contactObj.contact_number !== null ? `\nPh:${contactObj.contact_number}` : ''}`,
            },
          });
          setShipperData(e.target.value);

          const id = parseInt(contactObj.id);
          setShipperId(id);
          setObj({
            ...obj,
            shipperaddress: `${contactObj.company_name}${contactObj.address !== null?`\n${contactObj?.address}`:''}${contactObj.city !== null?`\n${contactObj?.city}`:''}${contactObj.state !== null?`\n${contactObj?.state}`:''}\n${contactObj.country_name}${contactObj.contact_number !== null ?`\nPh:${contactObj?.contact_number}`:''}`,
          });
        } else if (name === "consigneeData") {
          removeToolTip(name);
          onChange({
            target: {
              name: "consigneeaddress",
              value: `${contactObj.company_name}${contactObj.address !== null ? `\n${contactObj.address}` : ''}${contactObj.city !== null ? `\n${contactObj.city}` : ''}${contactObj.state !== null ? `\n${contactObj.state}` : ''}\n${contactObj.country_name}${contactObj.contact_number !== null ? `\nPh:${contactObj.contact_number}` : ''}`,
            },
          });
          setConsigneeData(e.target.value);
          setConsigneeId(parseInt(contactObj.id));
          setObj({
            ...obj,
            consigneeaddress: `${contactObj.company_name}${contactObj.address !== null?`\n${contactObj?.address}`:''}${contactObj.city !== null?`\n${contactObj?.city}`:''}${contactObj.state !== null?`\n${contactObj?.state}`:''}\n${contactObj.country_name}${contactObj.contact_number !== null ?`\nPh:${contactObj?.contact_number}`:''}`,
          });
        } else if (name === "notifyData") {
          setNotifyData(e.target.value);
          setNotifyPartyId(parseInt(contactObj.id));
          setObj({
            ...obj,
            notifyaddress: `${contactObj.company_name}${contactObj.address !== null?`\n${contactObj?.address}`:''}${contactObj.city !== null?`\n${contactObj?.city}`:''}${contactObj.state !== null?`\n${contactObj?.state}`:''}\n${contactObj.country_name}${contactObj.contact_number !== null ?`\nPh:${contactObj?.contact_number}`:''}`,
          });
        }
      }
    }
  };

  useEffect(() => {}, [shipperData]);

  const onChange = (e) => {
    const { name, value } = e.target;
    removeToolTip(name);
    if(name === "shipperaddress"){
      removeToolTip("shipperData")
    }else if (name === "consigneeaddress"){
      removeToolTip("consigneeData")
    }
    const maxLines = 9;
    const maxCharsLastLine = 60;
    if (name === "shipperaddress" || name === "consigneeaddress" || name === "notifyaddress") {
       
    // Split the text into lines
    let lines = e.target.value.split('\n');
    // Ensure that the number of lines doesn't exceed the maximum
    if (lines.length > maxLines) {
      // Truncate lines if the maximum number of lines is exceeded
      lines = lines.slice(0, maxLines);
    }
  
    // Iterate through each line and check for character limits
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > maxCharsLastLine) {
        // Split the line into multiple lines if it exceeds the character limit
        const overflow = lines[i].substring(maxCharsLastLine);
        lines[i] = lines[i].substring(0, maxCharsLastLine);
  
        // Insert the overflow text as a new line
        lines.splice(i + 1, 0, overflow);
  
        // Check if the maximum number of lines is exceeded after splitting
        if (lines.length > maxLines) {
          // Truncate lines again to meet the line limit
          lines = lines.slice(0, maxLines);
          break;
        }
      }
    }
  
    // Join the lines back together with newline characters
    const updatedText = lines.join('\n');
  
    // Assuming 'setObj' is a function to update some state
    // and 'obj' is the state object you want to update
    setObj({ ...obj, [name]: updatedText });
    } else {
      setObj({ ...obj, [name]: value });
    }
  };
  const [errorMessage, setErrorMessage] = useState({
    hasErrorInportLoading: false,
    hasErrorInportDischarge: false,
    hasErrorInissueAt: false,
    hasErrorInissuedOn: false,
    hasErrorInfmcFiling: false,
    hasErrorInbillingNo: false,
    hasErrorInshipperData: false,
    hasErrorInshipperaddress: false,
    hasErrorInconsigneeData: false,
    hasErrorInconsigneeaddress: false,
    hasErrorInissuedBy: false,
    hasErrorIndropData:false,
  });
  useEffect(() => {}, [errorMessage]);

  const {
    hasErrorInportLoading,
    hasErrorInportDischarge,
    hasErrorInissueAt,
    hasErrorInfmcFiling,
    hasErrorInbillingNo,
    hasErrorInshipperData,
    hasErrorInshipperaddress,
    hasErrorInconsigneeData,
    hasErrorInconsigneeaddress,
    hasErrorInissuedOn,
    hasErrorInissuedBy,
    hasErrorIndropData,
  } = errorMessage;

  const [errorField, setErrorField] = useState(null);

  const inputRefs = {
    focusPortLoading: React.createRef(),
    focusPortDischarge: React.createRef(),
    focusIssuedAt: React.createRef(),
    focusFmcFiling: React.createRef(),
    focusBillingNo: React.createRef(),
    focusShipperData: React.createRef(),
    focusShipperAddress: React.createRef(),
    focusConsigneeData: React.createRef(),
    focusConsigneeAddress: React.createRef(),
    focusIssuedBy: React.createRef(),
    // Add refs for all your input fields here
  };

  const [popupContent, setPopupContent] = useState("");

  const submitNewbol = (e) => {
    setIsWarningPopupOpen(true);
    setScrollPosition(0);
    setTimeout(() => {
      setIsWarningPopupOpen(false);
    }, 200); // Adjust the timeout duration as needed
    setErrorField(null);
    e.preventDefault();
    // if (focusErrorRef.current) {
    //   focusErrorRef.current.focus();
    // }
    const newError = { ...errorMessage };
    newError.hasErrorInportLoading = portLoading.length === 0;
    newError.hasErrorInportDischarge = portDischarge.length === 0;
    newError.hasErrorInissueAt = issueAt.length === 0;
    newError.hasErrorInissuedOn = issuedOn === '';
    newError.hasErrorInfmcFiling = fmcFiling.length === 0;
    newError.hasErrorInbillingNo = ( billingNo.length !== 10 );
    if(shipperaddress.length === 0){
      newError.hasErrorInshipperData = shipperData.length === 0;
    }
    if(consigneeaddress.length === 0){
      newError.hasErrorInconsigneeData = consigneeData.length === 0;
    }
    newError.hasErrorInshipperaddress = shipperaddress.length === 0;
    newError.hasErrorInconsigneeaddress = consigneeaddress.length === 0;
    newError.hasErrorInissuedBy = issuedBy.length === 0;
    // newError.hasErrorIndropData = dropData.length === 0;
    setErrorMessage(newError);
    const valuesArray = Object.values(newError);
    let flag = valuesArray.some((item, index) => item === true);

      // Perform your validation logic here
      // if(!shipperData) {
      //   setErrorField('focusShipperData');
      //   focusOnField('focusShipperData');
      //   WarningPopupShow('warning');
      //   return;
      // }
      if(!shipperaddress) {
        setErrorField('focusShipperData');
        focusOnField('focusShipperData');
        if(!shipperData){
          setErrorField('focusShipperData');
          focusOnField('focusShipperData');
        }
        WarningPopupShow('warning');
        return;
      }
      else if (billingNo.length !== 10) {
        setErrorField('focusBillingNo');
        focusOnField('focusBillingNo');
        WarningPopupShow('warning');
        return;
      }else if(!consigneeaddress) {
        setErrorField('focusConsigneeData');
        focusOnField('focusConsigneeData');
        WarningPopupShow('warning');
        if(!consigneeData) {
          setErrorField('focusConsigneeData');
          focusOnField('focusConsigneeData');
        }
        return;
      } else if(!portLoading) {
        setErrorField('focusPortLoading');
        focusOnField('focusPortLoading');
        WarningPopupShow('warning');
        return;
      }else if(!portDischarge) {
        setErrorField('focusPortDischarge');
        focusOnField('focusPortDischarge');
        WarningPopupShow('warning');
        return;
      }else if(!issueAt) {
        setErrorField('focusIssuedAt');
        focusOnField('focusIssuedAt');
        WarningPopupShow('warning');
        setScrollPosition(1600);
        return;
      }else if(!issuedBy) {
        setErrorField('focusIssuedBy');
        focusOnField('focusIssuedBy');
        WarningPopupShow('warning');
        setScrollPosition(1600);
        return;
      }else if(!fmcFiling) {
        setErrorField('focusFmcFiling');
        focusOnField('focusFmcFiling');
        WarningPopupShow('warning');
        setScrollPosition(1600);
        return;
      }else{
        setErrorField(null)
      }
     
      
      
    if (!flag) {
      popupShow("confirmSubmit");
    }
   
  };


    // Function to focus on an input field with a delay after the component has mounted
    const focusOnField = (fieldName) => {
      inputRefs[fieldName].current.focus();
      // setTimeout(() => {
      //   if (inputRefs[fieldName].current) {
      //     inputRefs[fieldName].current.focus();
      //   }
      // }, 0);
    };
    
    useEffect(() => {
      // Focus on the first input field with an error if errorField is set
      if (errorField) {
        focusOnField(errorField);
      }
    }, [errorField]);

  const bolSubmit = () => {
    dispatch(closePopup());
    const reformatIssuedOn = formatDate(issuedOn);
    const shipperDeclaredValues = [];
  const particularFurnsihed = [];

  rows.forEach((item, index) => {
  

    const freight_charges = item.values[0] ;
    const basics = item.values[1];
    const rate = item.values[2] ;
    const prepaid = item.values[3] ;
    const collect = item.values[4] ;
    const id=item.id

    const obj = {
      freight_charges,
      basics,
      rate,
      prepaid,
      collect,
      id
    };
      shipperDeclaredValues.push(obj);
    
  });

  rowsone.forEach((item, index) => {

    const container_number = item.values[0];
    const number_of_packages = item.values[1] ;
    const description_of_packages = item.values[2] ;
    const gross_weight = item.values[3];
    const measurement = item.values[4] ;
    const id=item.id

    const obj = {
      container_number,
      number_of_packages,
      description_of_packages,
      gross_weight,
      measurement,
      id
    };
      particularFurnsihed.push(obj);
   
  });

  
  

    createMutation({
      variables: {
        creator_id: creatorId,
        creator_name: creatorName,
        creator_email: creator_email,
        input: {
          // bol_data_id: 123,
          // bol_parent_id: 456,
          // bol_version_no: 1,
          shipper_id: shipperId?shipperId:"",
          consignee_id: consigneeId?consigneeId:"",
          notify_party_id: notifyPartyId?notifyPartyId:"",
          booking_number: bookingNumber,
          bol_number: billingNo,
          export_references: exportRef,
          forwarding_agent_fmc_number: fwdNo,
          delivery_apply_to: delivery,
          pre_carriage_by: preCarriage,
          pre_carrier_reciept_place: placeRe,
          export_carrier: exportCarrier,
          port_of_loading: portLoading,
          loading_pier_terminal: loadingTerminal,
          port_of_discharge: portDischarge,
          carrier_place_delivery: placeDelivery,
          number_of_originals: noOrginals,
          prepaid_total: prepaidTotal,
          collect_total: collectTotal,
          country_of_origin_goods: pointGoods,
          issued_at: issueAt,
          issued_on: reformatIssuedOn,
          issued_by_as: dropData?dropData:"As Agent of The Carrier of Qi Lines",
          issued_by: issuedBy,
          shipment_type: shipmentDropData?shipmentDropData:"N/A",
          selling_rate_authorization: fmcFiling,
          particulars_furnished: particularFurnsihed,
          shippers_declared_values: shipperDeclaredValues,
          accordance_with_regulations: remarkChecked,
          shipper_details: shipperaddress,
          consignee_details: consigneeaddress,
          notify_party_details: notifyaddress?notifyaddress:""
        
        },
      },
    });

  };

  const removeToolTip = (name) => {
    setErrorMessage((prevError) => ({
      ...prevError,
      [`hasErrorIn${name}`]: false,
    }));
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const formatDate = (dateString) => {
    const formatDateSetting = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return formatDateSetting.toLocaleDateString("en-US", options);
  };

  const handleDateChange = (date) => {
    removeToolTip('issuedOn');
    const adjustedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const formattedDate = adjustedDate.toISOString().substring(0, 10);
    const crctDate = formatDate(formattedDate);
    setSelectedDate(date);
    setIssuedOn(crctDate);
  };

  const handleSaveAndExit = (e) => {
    e.preventDefault();
 
    const newError = { ...errorMessage };
    newError.hasErrorInportLoading = false;
    newError.hasErrorInportDischarge = false;
    newError.hasErrorInissueAt = false;
    newError.hasErrorInissuedOn = false;
    newError.hasErrorInfmcFiling = false;
    newError.hasErrorInshipperData = false;
    newError.hasErrorInshipperaddress = false;
    newError.hasErrorInconsigneeData = false;
    newError.hasErrorInconsigneeaddress = false;
    newError.hasErrorInissuedBy = false;
    newError.hasErrorInbillingNo = ( billingNo.length !== 10 );
    setErrorMessage(newError);
    const valuesArray = Object.values(newError);
    let flag = valuesArray.some((item, index) => item === true);

    const reformatIssuedOn = formatDate(issuedOn);
    // const reformattedIssuedOn  = reformatIssuedOn === "Invalid Date" || "January 01, 1970" ? "" : reformatIssuedOn;
  //  console.log(reformattedIssuedOn,'reformattedIssuedOn')
    const shipperDeclaredValues = [];
    const particularFurnsihed = [];
  
    rows.forEach((item, index) => {
      const freight_charges = item.values[0] ;
      const basics = item.values[1];
      const rate = item.values[2];
      const prepaid = item.values[3];
      const collect = item.values[4];
      id=item.id
  
      const obj = {
        freight_charges,
        basics,
        rate,
        prepaid,
        collect,
        id
      };
  
        shipperDeclaredValues.push(obj);
    });
  
    rowsone.forEach((item, index) => {
  
      const container_number = item.values[0] ;
      const number_of_packages = item.values[1] ;
      const description_of_packages = item.values[2] ;
      const gross_weight = item.values[3] ;
      const measurement = item.values[4] ;
      id=item.id
      const obj = {
        container_number,
        number_of_packages,
        description_of_packages,
        gross_weight,
        measurement,
        id
      };
        particularFurnsihed.push(obj);
     
    });


 
    const bolCurrentVersionId = parseInt(currentVersionId);
    if(!flag){
    
    UpdateMutation({
      variables: {
        id: bolCurrentVersionId,
        input: {
          // bol_data_id: 123,
          // bol_parent_id: 456,
          // bol_version_no: 1,
          shipper_id: shipperId?shipperId:"",
          consignee_id: consigneeId?consigneeId:"",
          notify_party_id: notifyPartyId?notifyPartyId:"",
          booking_number: bookingNumber,
          bol_number: billingNo,
          export_references: exportRef,
          forwarding_agent_fmc_number: fwdNo,
          delivery_apply_to: delivery,
          pre_carriage_by: preCarriage,
          pre_carrier_reciept_place: placeRe,
          export_carrier: exportCarrier,
          port_of_loading: portLoading,
          loading_pier_terminal: loadingTerminal,
          port_of_discharge: portDischarge,
          carrier_place_delivery: placeDelivery,
          number_of_originals: noOrginals,
          prepaid_total: prepaidTotal,
          collect_total: collectTotal,
          country_of_origin_goods: pointGoods,
          issued_at: issueAt,
          issued_on: reformatIssuedOn === "Invalid Date" ? "" : reformatIssuedOn,
          issued_by_as: dropData?dropData:"As Agent of The Carrier of Qi Lines",
          issued_by: issuedBy,
          shipment_type: shipmentDropData?shipmentDropData:"N/A",
          selling_rate_authorization: fmcFiling,
          particulars_furnished: particularFurnsihed,
          shippers_declared_values: shipperDeclaredValues,
          accordance_with_regulations: remarkChecked,
          shipper_details: shipperaddress,
          consignee_details: consigneeaddress,
          notify_party_details: notifyaddress?notifyaddress:""
        },
      },
    });
  }else{
    focusOnField('focusBillingNo');
    WarningPopupShow('warning');
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  };

  const handleClearAll = () => {
    // e.preventDefault();
    setBillingNo("");
    // setNoOrginals("");
    setShipperData("");
    setConsigneeData("");
    setNotifyData("");
    setIssuedOn("");
    setShipmentDropData("");
    setRemarkChecked("");
    setDropData("");
    const newobj = { ...obj };
    newobj.exportRef = "";
    newobj.pointGoods = "";
    newobj.preCarriage = "";
    newobj.placeRe = "";
    newobj.exportCarrier = "";
    newobj.portLoading = "";
    newobj.loadingTerminal = "";
    newobj.portDischarge = "";
    newobj.placeDelivery = "";
    newobj.noOrginals = "";
    newobj.prepaidTotal = "";
    newobj.collectTotal = "";
    newobj.issueAt = "";
    newobj.fmcFiling = "";
    newobj.fwdNo = "";
    newobj.shipperaddress = "";
    newobj.consigneeaddress = "";
    newobj.delivery = "";
    newobj.notifyaddress = "";
    newobj.bookingNumber = "";
    newobj.issuedBy = "";
    setObj(newobj);
    setRows([
      {
        id: 1,
        values: ["", "", "", "", ""],
      },
      // {
      //   id: 2,
      //   values: ["", "", "", "", ""],
      // },
    ]);
    setRowsone([
      {
        id: 1,
        values: ["", "", "", "", ""],
      },
    ]);
  };

  const handleClearNotifyParty = () => {
    setNotifyData("");
    setNotifyPartyId("");
    const newobj = { ...obj };
    newobj.notifyaddress = "";
    setObj(newobj);
  }
  const handleClearShipper = () => {
    setShipperData("");
    setShipperId("");
    const newobj = { ...obj };
    newobj.shipperaddress = "";
    setObj(newobj);
  }
  const handleClearConsignee = () => {
    setConsigneeData("");
    setConsigneeId("");
    const newobj = { ...obj };
    newobj.consigneeaddress = "";
    setObj(newobj);
  }

  const viewBolAction = (param, id) => {
    if (findLocationAdminEdit === true) {
      navigate('/admin/view/bol',{state: { data: {bolId: id,creatorId:creatorId} },})

    } else {
      navigate('/bol/view',{state: { data: {bolId: id} },})
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);
  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPopupContent("confirmSubmit");
    dispatch(openPopup());
  };
  
  // // console.log(bolAllVersions[0],'bol')
  // if(bolAllVersions !== undefined){
  //   //  setBillingNo(bolAllVersions[0].bol_number);
  // }
  // if(validationFlag !== true){
  //     // goTop();

  // }

  useEffect(() => {
    if(findLocationNewBol === true){
      handleClearAll()
    }
    return ()=>{
    
    }
   }, [location]);

   const WarningPopupShow = () => {
     setPopupContent("warning");
     dispatch(openWarningPopup());
   }
   const handleScroll = () => {
    const position = window.pageYOffset;
    if(isWarningPopupOpen === true){
      setScrollPosition(position);
    }
};

useEffect(() => {
  if(isWarningPopupOpen === true){
    window.addEventListener('scroll', handleScroll, { passive: true });
  }
    return () => {

        window.removeEventListener('scroll', handleScroll);
    };
}, [isWarningPopupOpen]);

//  const handleRow = (e,row) => {
//   setRowId (row.id);
//   setPopupContent("handleRowDelete");
//   dispatch(openPopup());
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
//   }
  const handleDeleteRow = (e,row) => {
    const updatedRowsOne = rowsone.filter(item => item.id !== row.id);
    setRowsone(updatedRowsOne)
    toast.success("The row has been deleted and will not be included as a rider page in this HBL.");
  }
  const handleBack = () => {
    // dispatch(closeViewBol())
    if(findLocationAdmin === true){
      navigate('/admin/member')
    }else{
      navigate('/allbol')
    }
  }
  return (
    <>
    {(loading  || loading1 || loadingdetails || showLoader) && <Spinner/>}
     { popupContent === "warning" ? (<WarningPopup title="The highlighted field(s) must be filled and/or HBL# should be as per format. " scrollPosition={scrollPosition} setScrollPosition={setScrollPosition}/>):'' }
      {
        <Modal
          content={
            popupContent === "confirmSubmit" ? (
              <ConfirmPopup
                submitConfirm={bolSubmit}
                goTop={goTop}
                title="Do you really want to submit BOL form ?"
              />
            ) : popupContent === "Shipper" || "Consignee" || "Notify" ? (
              <BolPopUp
                title={
                  popupContent === "Shipper"
                    ? "Shipper"
                    : popupContent === "Consignee"
                    ? "Consignee"
                    : popupContent === "Notify"
                    ? "Notify"
                    : ""
                }
                mutation={createContactMutation}
              />
            ) : (
              ""
            )
          }
        />
      }

      <div className="content-wrapper newbol-tab newbol-form-add">
        <div className="new-bol-wrapper">
          {/* -------version- 1 header-start TAB BUTTON---------------------------------------- */}
          <div className="new-bol-header">
          
            <div className="newbol-msg-notifywrap notify-btn-wrapper">
              {/* <a className="newbol-msg-btn active">13 Messages</a> */}

              {findLocationAdminEdit === true ||
              findLocationMemberEdit === true ? (
                <a
                  className="clear-btn"
                  onClick={() => viewBolAction("viewBol", bolId)}
                >
                  View BOL
                </a>
              ) : (
                ""
              )}
              {findLocationAdminEdit !== true && findLocationMemberEdit !== true ? (
                <>
                <a className="clear-btn" onClick={handleClearAll}>
                  Clear All
                </a>
                    {/* <div className="bol-view-back-btn"> */}
                    <a className="clear-btn " onClick={handleBack}>Back</a>
                    {/* </div> */}
                    </>
              ) : (
                ""
              )}
              {/* <a className="clear-btn" onClick={()=>handleBack(bolId)}>Back</a> */}
            </div>
          </div>
          {/* -------version- 1 header-end--------------------------------------- */}
          {/* -------version- 1 Body TAB CONTENT---START------------------------------------- */}

          <div className="new-bol-tabopen">
            <form className="new-bol-form" onSubmit={submitNewbol}>
              <div className="newbol-form-box">
                <div className="newbol-fillig-block b-none">
                  <div className="newbol-filling-item newbol-filling-item-fullwidth">
                    <div className="new-bol-input-wrap border-text">
                      <label>Shipment Type</label>
                       <select
                        name="shipmentDropData"
                        value={shipmentDropData}
                        onChange={(e) => setShipmentDropData(e.target.value)}
                      >
                          {/* <option value="N/A">N/A</option> */}
                        {shipmentDrop.map((optns, index) => (
                          <option key={index} value={optns}>
                            {optns}
                          </option>
                        ))}
                      </select>  
                    </div>
                  </div>
                </div>
                <div className="newbol-fillig-block">
                  <div className="newbol-filling-item">
                    <div
                      className="new-bol-input-wrap border-text"
                      style={{
                        border: hasErrorInshipperData ? "1px solid red" : "",
                      }}
                    >
                      <label
                        className={hasErrorInshipperData ? "errormsg" : ""}
                      >
                        Shipper/Exporter<span>*</span>
                      </label>
                      {findLocationAdmin !== true ? 
                          <div className="newshipper-add-wrap">
                          <p onClick={() => popupShow("Shipper")}>
                            <span>+</span>New
                          </p>
                        </div>:''
                      }
                    
                      <select
                        name="shipperData"
                        value={shipperData}
                        onChange={dropList}
                        // onFocus={removeToolTip}
                        ref={inputRefs.focusShipperData}
                      >
                        <option value="defaultShipper">Type/Select the Shipper/Exporter</option>
                        {contactArray?.map((opt, index) => (
                          <option
                            key={opt.id}
                            data-custom-attribute={opt.id}
                            value={opt.company_name}
                          >
                            {opt.company_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="new-bol-input-wrap ship-exporter-textarea border-text"
                      style={{
                        border: hasErrorInshipperaddress ? "1px solid red" : "",
                      }}
                    >
                      <label
                        className={hasErrorInshipperaddress ? "errormsg" : ""}
                      >
                        Complete Name & Address<span>*</span>
                      </label>
                      <textarea
                        value={shipperaddress}
                        // onFocus={removeToolTip}
                        name="shipperaddress"
                        onChange={onChange}
                        ref={inputRefs.focusShipperAddress}
                      />
                    </div>
                  </div>
                  <div className="newbol-filling-item double-column">
                    <div className="newbol-filling-row-merge none-flex-column">
                      <div className="new-bol-input-wrap border-text">
                        <label>Booking No.</label>
                        <input
                          type="text"
                          onChange={onChange}
                          value={bookingNumber?bookingNumber:''}
                          name="bookingNumber"
                        ></input>
                        {/* <CustomInput  type="number"  pattern="[0-9]*"  defaultValue={bookingNumber}  name="bookingNumber" onChange={onChange}/> */}
                      </div>
                      <div
                        className="new-bol-input-wrap border-text"
                        style={{
                          border: hasErrorInbillingNo ? "1px solid red" : "",
                        }}
                      >
                        <label
                          className={hasErrorInbillingNo ? "errormsg" : ""}
                        >
                          Bill of Lading No<span>*</span>
                        </label>
                        <CustomInput
                          maxLength={10}
                          type="text"
                          value={billingNo}
                          name="billingNo"
                          // onFocus={removeToolTip}
                          onChange={handleInputChange}
                          reference={inputRefs.focusBillingNo}
                        />
                      </div>
                    </div>
                    <div className="newbol-filling-row-merge export-refernce">
                      <div className="new-bol-input-wrap border-text">
                        <label>Export References</label>
                        <CustomInput
                          type="text"
                          value={exportRef?exportRef:''}
                          name="exportRef"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="newbol-fillig-block">
                  <div className="newbol-filling-item">
                    <div
                      className="new-bol-input-wrap border-text"
                      style={{
                        border: hasErrorInconsigneeData ? "1px solid red" : "",
                      }}
                    >
                      <label
                        className={hasErrorInconsigneeData ? "errormsg" : ""}
                      >
                        Consignee<span>*</span>
                      </label>
                      {findLocationAdmin !== true ?
                      <div className="newshipper-add-wrap">
                      <p onClick={() => popupShow("Consignee")}>
                        <span>+</span>New
                      </p>
                      </div>:''}
                      
                      <select
                        name="consigneeData"
                        value={consigneeData}
                        onChange={dropList}
                        // onFocus={removeToolTip}
                        ref={inputRefs.focusConsigneeData}
                      >
                        <option value="defaultConsignee">Type/Select the Consignee</option>
                        {contactArray?.map((optns, index) => (
                          <option
                            key={index}
                            data-custom-attribute={optns.id}
                            value={optns.company_name}
                          >
                            {optns.company_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="new-bol-input-wrap consign-textarea border-text"
                      style={{
                        border: hasErrorInconsigneeaddress
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <label
                        className={hasErrorInconsigneeaddress ? "errormsg" : ""}
                      >
                        Complete Name & Address<span>*</span>
                      </label>
                      <textarea
                        value={consigneeaddress}
                        // onFocus={removeToolTip}
                        name="consigneeaddress"
                        onChange={onChange}
                        ref={inputRefs.focusConsigneeAddress}
                      />
                    </div>
                  </div>
                  <div className="newbol-filling-item forward-agent-box">
                    <div className="new-bol-input-wrap border-text">
                      <label className="d-block">
                        Forwarding Agent F.M.C No.
                      </label>
                      <textarea
                        className="forwarding-agent-num"
                        name="fwdNo"
                        value={fwdNo?fwdNo : ""}
                        onChange={onChange}
                      />
                    </div>
                    <div className="new-bol-input-wrap border-text">
                      <label className="d-block">
                        Point and Country of Origin of Goods
                      </label>
                      <CustomInput
                        type="text"
                        value={pointGoods?pointGoods:''}
                        name="pointGoods"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="newbol-fillig-block">
                  <div className="newbol-filling-item">
                    <div className="new-bol-input-wrap border-text">
                      <label>Notify Party</label>
                      {findLocationAdmin !== true ?
                      <div className="newshipper-add-wrap">
                      <p onClick={() => popupShow("Notify")}>
                        <span>+</span>New
                      </p>
                    </div>:''}
                      
                      <select
                        name="notifyData"
                        value={notifyData}
                        onChange={dropList}
                      >
                        <option value="defaultNotifyParty">Type/Select the Notify Party</option>
                        {contactArray?.map((opt, index) => (
                          <option key={index} value={opt.company_name}>
                            {opt.company_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="new-bol-input-wrap border-text">
                      <label>Complete Name & Address</label>
                      <textarea
                        className="notify-address"
                        name="notifyaddress"
                        value={notifyaddress?notifyaddress:''}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="newbol-filling-item single-item-center">
                    <div className="new-bol-input-wrap border-text">
                      <label className="d-block">
                        For Delivery Please apply to
                      </label>
                      
                      <textarea
                        type="text"
                        className="point-good-single-item"
                        value={delivery?delivery:''}
                        name="delivery"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="newbol-fillig-block">
                  <div className="newbol-filling-item double-column">
                    <div className="newbol-filling-row-merge">
                      <div className="new-bol-input-wrap pre-carriage-width border-text">
                        <label>Pre-Carriage By</label>
                        <CustomInput
                          type="text"
                          placeholder="Enter"
                          value={preCarriage?preCarriage:''}
                          name="preCarriage"
                          onChange={onChange}
                        />
                      </div>
                      <div className="new-bol-input-wrap border-text">
                        <label>Place of Receipt by Pre-carrier</label>
                        <CustomInput
                          type="text"
                          placeholder="Enter"
                          value={placeRe?placeRe:''}
                          name="placeRe"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="newbol-fillig-block">
                  <div className="newbol-filling-item double-column">
                    <div className="newbol-filling-row-merge">
                      <div className="new-bol-input-wrap export-width border-text">
                        <label>
                          Export Carrier{" "}
                          <span className="label-detail">
                            (Vessels/Voy/Flag)
                          </span>
                        </label>
                        <CustomInput
                          type="text"
                          placeholder="Enter"
                          value={exportCarrier?exportCarrier:''}
                          name="exportCarrier"
                          onChange={onChange}
                        />
                      </div>
                      <div
                        className="new-bol-input-wrap port-loading-width border-text"
                        style={{
                          border: hasErrorInportLoading ? "1px solid red" : "",
                        }}
                      >
                        <label
                          className={hasErrorInportLoading ? "errormsg" : ""}
                        >
                          Port of Loading<span>*</span>
                        </label>
                        <CustomInput
                          type="text"
                          placeholder="Enter"
                          value={portLoading}
                          name="portLoading"
                          onChange={onChange}
                          // onFocus={(e) => removeToolTip(e)}
                          reference={inputRefs.focusPortLoading}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="newbol-filling-item single-item-center mt-0">
                    <div className="new-bol-input-wrap border-text">
                      <label className="d-block">Loading Pier/Terminal</label>
                      <CustomInput
                        type="text"
                        placeholder="Enter"
                        value={loadingTerminal?loadingTerminal:''}
                        name="loadingTerminal"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="newbol-fillig-block">
                  <div className="newbol-filling-item double-column">
                    <div className="newbol-filling-row-merge">
                      <div
                        className="new-bol-input-wrap port-discharge-width border-text"
                        style={{
                          border: hasErrorInportDischarge
                            ? "1px solid red"
                            : "",
                        }}
                      >
                        <label
                          className={hasErrorInportDischarge ? "errormsg" : ""}
                        >
                          Port of Discharge<span>*</span>
                        </label>
                        <CustomInput
                          type="text"
                          placeholder="Enter"
                          value={portDischarge}
                          name="portDischarge"
                          onChange={onChange}
                          // onFocus={removeToolTip}
                          reference={inputRefs.focusPortDischarge}
                          style={{
                            border: hasErrorInportDischarge
                              ? "1px solid red"
                              : "",
                          }}
                        />
                      </div>
                      <div className="new-bol-input-wrap border-text">
                        <label>Place of Delivery On Carrier</label>
                        <CustomInput
                          type="text"
                          placeholder="Enter"
                          value={placeDelivery?placeDelivery:''}
                          name="placeDelivery"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="newbol-filling-item single-item-center mt-0">
                    <div className="new-bol-input-wrap border-text">
                      <label className="d-block">Number of Orginals</label>
                      <CustomInput
                        type="number"
                        placeholder="Enter"
                        value={noOrginals?noOrginals:''}
                        name="noOrginals"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="newbol-fillig-block  new-bol-table-form">
                  <div className="newbol-filling-item">
                    <div className="table-structure-block">
                      <div className="table-heading-new-row">
                        <h5 style={{textTransform: 'uppercase'}}>Particulars Furnished by Shipper</h5>
                          <p className="addrow" onClick={addNew}>
                          Add a Page <img src="/images/Info_alt_light.svg" alt="" />
                        </p>
                       
                      </div>
                      <div className="table-structure-wrap">
                        <div className="table-outer">
                          <EditableTable
                            rows={rowsone}
                            tableheading={tableonheading}
                            handleInputChanges={handleInputChangetwo}
                            flag={true}
                            references={perticularTextareaRefs}
                            handleDeleteRow={handleDeleteRow}
                            // tableHeadClassName={'table-structure-head-item sibling-table-item Particulardeclaretable th:nth-child'}
                            // tableRowClassName ={'table-data-enter first-table-item Particulardeclaretable th:nth-child'}
                            // param = {'perticularTable'}
                          />
                        </div>
                      </div>
                      <div className="table-checkbox-text">
                        <label className="form-checkbox">
                          <input
                            type="checkbox"
                            name="check_remember"
                            checked={remarkChecked}
                            onChange={handleCheckboxChange}
                          />
                          These commodities, technology or software were
                          exported from the United States in accordance with the
                          Export Administration regulations. Diversion contrary
                          to United States law prohibited.
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="newbol-fillig-block  new-bol-table-form">
                  <div className="newbol-filling-item">
                    <div className="table-structure-block">
                      <div className="table-heading-new-row">
                        <h5 style={{textTransform: 'uppercase'}}>Shippers Declared Values</h5>
                      
                         {/* <p className="addrow" onClick={handleAddRow}>
                          Add New Row
                        </p> */}
                      </div>
                      <h5>
                      Subject to Extra Freight as per our Tariff and Clauses of this B/L
                      </h5>
                      <div className="table-structure-wrap">
                        <div className="table-outer">
                          <div className="table-mini">
                            <EditableTable
                              rows={rows}
                              tableheading={tabletwoheading}
                              handleInputChanges={handleInputChanges}
                              flag={false}
                              references={shipperTextareaRefs}
                              // tableHeadClassName ={'table-structure-head-item sibling-table-item'}
                              // tableRowClassName ={'table-data-enter first-table-item'}
                              // param = {'shipperTable'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="newbol-fillig-block"
                  style={{ borderBottom: "2px solid #a1a6ac" }}
                >
                  <div className="newbol-filling-item description-text ">
                    <div className="newbol-filling-item-inner-scroll ">
                      <div className="scrollwrapper" id="scroll_2">
                        <div id="scroller_2">
                          <div className="scroll-inner">
                            <p>
                              <strong>RECEIVED</strong> by the Carrier the Goods
                              as specified above in apparent good order and
                              condition unless otherwise stated, to be
                              transported to such place as agreed, authorized or
                              permitted herein and subject to all the terms and
                              conditions appearing on the front and reverse of
                              this Bill of Lading to which the Merchant agrees
                              by accepting this Bill of Lading, and local
                              privileges and customers not withstanding. The
                              particulars given above as stated by the shipper
                              and the weight, measure, quantity, condition,
                              contents and value of the Goods are unknown to the
                              Carrier.
                            </p>
                            <p>
                              In WITNESS whereof three (3) original Bills of
                              Lading have been signed if not otherwise stated
                              above, the same being accomplished the other(s),
                              if any, to be void. If required by the Carrier one
                              (1) original Bill of Lading must be surrendered
                              duly endorsed in exchange for the Goods or
                              delivery order.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="newbol-filling-item double-column">
                    <div className="double-column-input">
                      <div className="double-column-input-left">
                        <div className="new-bol-input-wrap border-text">
                          <label>Prepaid Total</label>
                          <CustomInput
                            type="number"
                            placeholder="Enter"
                            value={prepaidTotal?prepaidTotal:''}
                            name="prepaidTotal"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="double-column-input-right">
                        <div className="new-bol-input-wrap border-text">
                          <label>Collect Total</label>
                          <CustomInput
                            type="number"
                            placeholder="Enter"
                            value={collectTotal?collectTotal:''}
                            name="collectTotal"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="double-column-input">
                      <div className="double-column-input-left">
                        <div
                          className="new-bol-input-wrap border-text"
                          style={{
                            border: hasErrorInissueAt ? "1px solid red" : "",
                          }}
                        >
                          <label
                            className={hasErrorInissueAt ? "errormsg" : ""}
                          >
                            Issued At<span>*</span>
                          </label>
                          <CustomInput
                            type="text"
                            placeholder="Enter"
                            value={issueAt}
                            name="issueAt"
                            onChange={onChange}
                            // onFocus={removeToolTip}
                            reference={inputRefs.focusIssuedAt}
                          />
                        </div>
                      </div>
                      <div className="double-column-input-right">
                        <div
                          className="new-bol-input-wrap border-text"
                          style={{
                            border: hasErrorInissuedOn ? "1px solid red" : "",
                          }}
                        >
                          <label
                            className={hasErrorInissuedOn ? "errormsg" : ""}
                          >
                            On<span>*</span>
                          </label>
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd-MMMM-yyyy"
                            placeholderText={issuedOn}
                            value={selectedDate}
                            name="issuedOn"
                            // onFocus={removeToolTip}
                          />

                          {/* <DatePicker selected={issuedOn} value={issuedOn}  dateFormat="dd-MMMM-yyyy" onChange={(date) => datePickerForissuedOn(date)}  /> */}
                          {/* <DatePicker className="custom-datepicker" name="issuedOn" value={issuedOn} onChange={onChange} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="double-column-input-right border b-none">
                      <div className="new-bol-input-wrap border-text"   style={{
                            border: hasErrorInissuedBy ? "1px solid red" : "",
                          }}>
                           <label
                            className={hasErrorInissuedBy ? "errormsg" : ""}
                          >
                            Issued By<span>*</span>
                          </label>
                        <div className="new-bol-input-wrap">
                          <CustomInput
                            type="text"
                            placeholder="Enter"
                            value={issuedBy}
                            name="issuedBy"
                            onChange={onChange}
                            // onFocus={removeToolTip}
                            reference={inputRefs.focusIssuedBy}
                          />
                        </div>
                      </div>

                      <div className="new-bol-input-wrap border-text"    style={{
                        border: hasErrorIndropData ? "1px solid red" : "",
                      }}>
                           <label
                        className={hasErrorIndropData ? "errormsg" : ""}
                      >
                        As<span>*</span>
                      </label>
                        <select
                          name="dropData"
                          value={dropData}
                          onChange={(e) => setDropData(e.target.value)}
                          // onFocus={removeToolTip}
                        >
                          {drop.map((optns, index) => (
                            <option key={index} value={optns}>
                              {optns}
                            </option>
                          ))}
                          {/* onChange={onChange} */}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="FMC-filling-wrap">
                    <p>
                      <label className={hasErrorInfmcFiling ? "errormsg" : ""}>
                        <em className="bold-italic">NOT Authorized</em> for shipping to or from the United States
                        <em className="bold-italic">until further notice</em> selling rate for U.S.A. (for FMC
                        Filing)<span>*</span>
                      </label>
                    </p>

                    <div
                      className="new-bol-input-wrap"
                      style={{
                        border: hasErrorInfmcFiling ? "1px solid red" : "",
                      }}
                    >
                      <CustomInput
                        type="text"
                        placeholder="Enter"
                        value={fmcFiling}
                        name="fmcFiling"
                        onChange={onChange}
                        // onFocus={removeToolTip}
                        reference={inputRefs.focusFmcFiling}
                      />
                    </div>
                  </div>

                  <div className="submit-save-btn-wrap">
                    {findLocationAdminEdit === true ? (
                      <button
                        style={{ display: isVisible ? "block" : "none" }}
                        {...(validationFlag !== true ? goTop() : "")}
                        className="submit-hover"
                      >
                        Save
                      </button>
                    ) : (
                      <>
                        <button
                          style={{ display: isVisible ? "block" : "none" }}
                          {...(validationFlag !== true ? goTop() : "")}
                          className="submit-hover"
                        >
                          Submit
                        </button>

                        {/* <button
                          className="save-exit-btn"
                          onClick={handleSaveAndExit}
                        >
                          Save &amp; Exit
                        </button> */}
                      </>
                    )}

                    
                    {(findLocationNewBol === true || versionStatus === 'Draft') && findLocationAdmin !== true ?
                        <button
                        className="save-exit-btn"
                        onClick={handleSaveAndExit}
                      >
                        Save &amp; Exit
                      </button>
                    :""}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BolContent;
