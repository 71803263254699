import React from "react"
const EditableTable=({rows,handleInputChanges,tableheading,flag,references = React.createRef(),handleDeleteRow})=>{
  const MIN_TEXTAREA_HEIGHT = flag?210:70;
  return(
              <table className="main-table-data">
                <thead className="table-structure-head">
                  <tr>
                    {
                       tableheading.map((item,index)=>{
                           return(
                            <th key={index} className="table-structure-head-item sibling-table-item" style={{
                              width:flag &&index===0?"16%":flag &&index===1?"10%":flag && index===2?"42%":flag && index===3?"16%":flag && index===4?"16%":''
                            }} >{item}</th>
                           )

                       })


                    }
                  
                  </tr></thead>
                <tbody>
                {rows?.map((row,key)=>{
                     return(
                        <tr key={key} className="table-structure-body">
                            {
                                row?.values?.map((value,columnindex)=>{
                                     return(
                                        <td      key={columnindex} className={flag && columnindex===4?"table-data-enter first-table-item  remove-btn-td":'table-data-enter first-table-item '}>
                                        <textarea  ref={references[key][columnindex]} style={{height:MIN_TEXTAREA_HEIGHT,resize:'none'}} value={value?value:''} onChange={(e)=>handleInputChanges(e,row,columnindex)} />
                                        {flag && columnindex===4 && rows?.length > 1 && <div className="remove-row-btn" onClick={(e)=>handleDeleteRow(e,row)}></div>}
                                        </td>
                                     )

                                })
                            }
                      </tr>
                     )
                })}
                </tbody>
              </table>
 
  )

}
export default EditableTable