import { closePopup } from "../features/popup/popupSlice";
import { useDispatch } from "react-redux";
import CustomInput from "./common/CustomInput";
import { useState } from "react";
import { APPROVE_REJECT_BOL_MUTATION } from "../mutation/mutation";
import { client3 } from "../services/appolo";
import { useMutation } from "@apollo/client";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Spinner from "./common/Spinner";
const ApproveRejectPopup = ({Id,title,message}) => {
    
    const [obj,setObj]=useState({
       reason: ''
      })
      const {reason} =obj
    const onChange=(e)=>{
            
        const {name,value}=e.target
        setObj({...obj,[name]:value})
    }
    const action = title.toLowerCase();
    const navigate = useNavigate();
    const[errorMessage,setErrormessage]=useState({
        hasErrorInreason:false,
    })
    const dispatch=useDispatch()
    const onClose=(e)=>{
        e.stopPropagation();
        dispatch(closePopup())
     }

     const{hasErrorInreason}=errorMessage

     const [approveORRejectBol,{loading}] = useMutation(APPROVE_REJECT_BOL_MUTATION ,{
        client: client3,
        onCompleted:(data)=>{
            toast.success('Bol updated successfully.')
            dispatch(closePopup())
            navigate('/admin/view/bol',{state: { data: {bolId: data.takeBolAction.id,creatorId:data.takeBolAction.creator_id} },})
            window.location.reload();
        },
        onError: () => {
            // Show an error toast
          },
      
      });

     const validateApproveRejectPopup = (e) => {
        e.preventDefault()
        const newError = {...errorMessage}
        // newError.hasErrorInreason = reason.length === 0

        setErrormessage(newError)
        const valuesArray = Object.values(newError)
        let flag=valuesArray.some((item,index)=>item===true)
        if(!flag){
            approveORRejectBol({
                variables:{
                    action:action,
                    id:Id,
                    message:reason
                }
            })
        }
     }

     const removeError=(e)=>{
        const{name}=e.target
        setErrormessage((prevError) => ({ ...prevError, [`hasErrorIn${name}`]: false }));
     }

    //  useEffect(() => {
    //     const textarea = document.querySelector('textarea');
    //     autosize(textarea);
    
    //     return () => {
    //       autosize.destroy(textarea);
    //     };
    //   }, []);

    return (
        <>
        {loading && <Spinner/>}
        <div className="popupbox-content">
             <h3 style={{textAlign:"center",padding:"0px"}}>Are you sure?</h3>
        <h6 style={{textAlign:"center",padding:"5px 0px 20px 0px"}}>Do you want to {action} this BOL?</h6>
       <form onSubmit={validateApproveRejectPopup}>
        <div className="input-wrap approve-reject-popup-textarea">
               <label style={{ color: hasErrorInreason ? 'red' : '' }} className="label_style"> {message}<span style={{color: "#ff6000"}}></span></label>
                     <textarea  value={reason} name="reason" onChange={onChange} onFocus={removeError}   />
                 </div>
       
         <div className="buttoncover">
        <a onClick={(e)=>{onClose(e)}} className="deletebutton">Cancel</a>
         <CustomInput type="submit" className="deletebutton cancelbutton" value={title}/>      
            </div>
           </form>
       </div>
    </>
    )
}
export default ApproveRejectPopup